import React from 'react';
import {getRoutes} from '../components/shared/routes';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from '../components/shared/Footer/Footer';
import PartnersNetwork from "../components/sections/partnersNetwork/PartnersNetwork";
import HomeSplash3 from "../components/sections/homeSplash/HomeSplash3";
import HomeSection2 from "../components/sections/HomeSection2/HomeSection2";
import Innovations from "../components/sections/innovations/Innovations";
import Regulations from "../components/sections/regulations/Regulations";
import HomeMobileApps from "../components/sections/HomeMobileApps/HomeMobileApps";
import Platform from "../components/sections/Platform/Platform";
import HomeNewsList from "../components/pages/home/partials/HomeNews/HomeNewsList";
import RiskMitigationLevels from "../components/sections/RiskMitigationLevels/RiskMitigationLevels";
import SatelliteImageryDeforestationAnalysis
    from "../components/sections/SatelliteImageryDeforestationAnalysis/SatelliteImageryDeforestationAnalysis";
import TechnologyPartner from "../components/sections/TechnologyPartner/TechnologyPartner";

const HomePage = () => {
    const routes = getRoutes().home;

    return (
        <Layout>
            <SEO/>

            <HomeSplash3/>

            <div id={routes.sections.timberId.anchor}>
                <HomeSection2/>
            </div>

            {/*<RiskMitigationLevels/>*/}

            <SatelliteImageryDeforestationAnalysis/>

            <Innovations/>

            <Platform/>

            <HomeMobileApps/>

            <Regulations/>

            <TechnologyPartner/>

            {/*<HomeNewsList/>*/}

            <Footer/>
        </Layout>
    );
};

export default HomePage;

