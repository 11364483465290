import React from "react";
import css from './homeSplash3.module.scss';
import FullscreenVideosLoop3 from "../../shared/fullscreenVideosLoop/FullscreenVideosLoop3";
import Movie04 from "./movie04.mp4";
import DeeplaiEUDRLogo from "../../../images/logos/TimberID_EUDR_logo.svg";
import PdfWhiteIco from "../../../images/icons/document_pdf_white.svg";
import {getRoutes} from "../../shared/routes";
import MainButton, {mainButtonArrow, mainButtonFill} from "../../shared/MainButton/MainButton";
import LeafsIcon from "../../../images/icons/leafs_symbol_icon.svg";

const HomeSplash3 = () => {
    const routes = getRoutes().home;

    const listItem = (text) => {
        return (
            <div className={css.listItem}>
                <img src={LeafsIcon} alt="Leafs Icon"/>
                {text}
            </div>
        )
    }

    return (
        <div className={css.wrapper}>
            <div className={css.wrapperBg}/>
            <div className={css.videosWrapper}>
                <FullscreenVideosLoop3
                    videos={[
                        Movie04,
                    ]}
                />
                <div className={css.gradient}/>
            </div>
            <div className={css.content}>
                <div className={css.logo}>
                    <img src={DeeplaiEUDRLogo} alt=""/>
                </div>
                <div className={css.column}>
                    <div className={css.textWrapper}>
                        {listItem('Due Diligence Statement Creation')}
                        {listItem('Provenance Geo-location')}
                    </div>
                    <div className={css.textWrapper}>
                        {listItem('Authentication Automation')}
                        {listItem('Traceability Transparency')}
                    </div>
                </div>

                <div className={css.buttonsWrapper}>
                    <a href={`/#${routes.sections.timberId.anchor}`}>
                        <MainButton label={"Start now"} arrow={mainButtonArrow.right} fill={mainButtonFill.gradient}/>
                    </a>
                    <a href={"/files/Deeplai_TimberID_EUDR_compliance-system.pdf"}>
                        <MainButton label={"Presentation"} arrow={mainButtonArrow.rightDown} leftIcon={PdfWhiteIco}/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default HomeSplash3;
